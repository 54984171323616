<!-- 
  Display Extended Health Benefits details.  

  Ex:

  Description
  The cost of airfare for six economy seats to accommodate a stretcher on ...

  Provisions
  - 80% reimbursement.
  - Claim submission deadline: 24 months from date of service.

  Eligibility
  - Please refer to the Toll Free number on your card ...
  - Maximum reimbursement is $420 payable for each participant every calendar ...

  Claim Requirement(s)
  - Original paid-in-full receipt and prescription from a medical doctor.
  - Expense eligible based on the required number of seats to a maximum of ...

-->

<template>
	<BCard no-body class="ehb-details">
		<BaseDisplayGroup
			v-if="benefitDetails.description"
			:automation-id="getAutomationId('ehb-description')"
			:label="$t('description')"
		>
			{{ benefitDetails.description }}
		</BaseDisplayGroup>

		<BaseDisplayGroup
			v-if="benefitDetails.provisions"
			:automation-id="getAutomationId('ehb-provisions')"
			:label="$t('provisions')"
		>
			<ul>
				<li v-for="(provision, pKey) in benefitDetails.provisions" :key="pKey">
					{{ provision }}
				</li>
			</ul>
		</BaseDisplayGroup>

		<BaseDisplayGroup
			v-if="benefitDetails.eligibility"
			:automation-id="getAutomationId('ehb-eligibility')"
			:label="$t('eligibility')"
		>
			<ul>
				<li v-for="(eligibility, eKey) in benefitDetails.eligibility" :key="eKey">
					{{ eligibility }}
				</li>
			</ul>
		</BaseDisplayGroup>

		<BaseDisplayGroup
			v-if="benefitDetails.claimRequirements"
			:automation-id="getAutomationId('ehb-requirements')"
			:label="$t('requirements')"
		>
			<ul>
				<li v-for="(requirement, rKey) in benefitDetails.claimRequirements" :key="rKey">
					{{ requirement }}
				</li>
			</ul>
		</BaseDisplayGroup>
	</BCard>
</template>

<script>
import IdMixin from '@/mixins/id';
import { BCard } from 'bootstrap-vue';
import BaseDisplayGroup from '@/components/common/base/BaseDisplayGroup.vue';

export default {
	name: 'EHBDetailsCard',
	components: {
		BCard,
		BaseDisplayGroup
	},
	mixins: [IdMixin],
	props: {
		benefitDetails: {
			type: Object,
			required: true
		}
	}
};
</script>

<style lang="scss" scoped>
.ehb-details {
	background: transparent;
}
.card {
	border: none;
	margin-bottom: 15px;
}

.display-group {
	margin-bottom: 0;
}
</style>

<i18n>
{
  "en": {
    "description": "Description",
    "provisions": "Provisions",
    "eligibility": "Eligibility",
    "requirements": "Claim Requirement(s)"
  },
  "fr": {
    "description": "Description",
    "provisions": "Dispositions",
    "eligibility": "Admissibilité",
    "requirements": "Critères pour les demandes de règlement"
  }
}
</i18n>
